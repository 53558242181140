import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
    maxWidth: '100%',
  },
});

const BlogGIF = (props) => {
  const classes = useStyles();

  return <img className={classes.root} alt="gif" {...props} />;
};

export default BlogGIF;
