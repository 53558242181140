import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    '& li': {
      marginTop: '1.5rem !important',
    },
    '& li::marker': {
      fontSize: '1.5rem',
      marginTop: 10,
    },
  },
  item0: {
    '&::marker': {
      color: '#e53935',
    },
  },
  item1: {
    '&::marker': {
      color: '#fdd835',
    },
  },
  item2: {
    '&::marker': {
      color: '#4caf50',
    },
  },
  item3: {
    '&::marker': {
      color: '#2196f3',
    },
  },
});

const categories = [
  'Severe difficulty (0 - 40)',
  'Moderate difficulty (40 - 67)',
  'Optimal Clarity (67 - 90)',
  'Too simple (90 - 100)',
];

const ClarityRanges = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root} {...props}>
      {categories.map((category, i) => {
        return (
          <li key={i} className={classes[`item${i}`]}>
            {category}
          </li>
        );
      })}
    </ul>
  );
};

export default ClarityRanges;
