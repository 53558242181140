import React from 'react';
import ReactCompareImage from 'react-compare-image';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '3rem',
    marginBottom: '-0.46em',
    '& img': {
      userDrag: 'none',
      userSelect: 'none',
    },
    '& > div > div > div:nth-child(2)': {
      cursor: 'pointer',
      backgroundColor: '#FFF',
      width: '3rem !important',
      height: '3rem !important',
      '& div:nth-child(1)': {
        borderWidth: '0.4rem !important',
        borderColor:
          'rgba(0, 0, 0, 0) #081d34 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important',
        marginLeft: '-0.75rem !important',
        marginRight: '0.75rem !important',
      },
      '& div:nth-child(2)': {
        borderWidth: '0.4rem !important',
        borderColor:
          'rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #081d34 !important',
        marginRight: '-0.75rem !important',
      },
    },
  },
});

const BlogImageCompare = props => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ReactCompareImage sliderLineWidth={0} hover={true} {...props} />
    </div>
  );
};

BlogImageCompare.propTypes = {
  ...ReactCompareImage.propTypes,
};

export default BlogImageCompare;
