// gatsby-browser.js
import '@fontsource/roboto';
import '@fontsource/lato';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

export const onRouteUpdate = ({ location }) => {
    if (process.env.NODE_ENV !== 'production') return null;

    const pagePath = location
        ? location.pathname + location.search + location.hash
        : undefined;
    setTimeout(() => {
        if (typeof window !== 'undefined') {
            if(typeof window.gtag === 'function') {
                window.gtag('event', 'page_view', { page_path: pagePath });
            }
            if(typeof window.rdt === 'function') {
                window.rdt('track', 'ViewContent');
            }
        }
    }, 100);
};