import Box from "@material-ui/core/Box";
import { Fragment } from "react";
import Caption from "../../../../src/components/Caption";
import BlogGIF from "../../../../src/components/BlogGIF";
import ImageSideBySide from "../../../../src/components/ImageSideBySide";
import ControlAoI from "../../../../content/blog/quick-a-b-testing-of-a-live-website/images/DisneyPlus_Control_aoi_half.png";
import VariantAoI from "../../../../content/blog/quick-a-b-testing-of-a-live-website/images/DisneyPlus_Variant_aoi_half.png";
import AnalyzingControl from "../../../../content/blog/quick-a-b-testing-of-a-live-website/images/analyzing_control_version_small.gif";
import CreatingVariant from "../../../../content/blog/quick-a-b-testing-of-a-live-website/images/creating_variant_small.gif";
import AnalyzingVariant from "../../../../content/blog/quick-a-b-testing-of-a-live-website/images/analyzing_variant_small.gif";
import * as React from 'react';
export default {
  Box,
  Fragment,
  Caption,
  BlogGIF,
  ImageSideBySide,
  ControlAoI,
  VariantAoI,
  AnalyzingControl,
  CreatingVariant,
  AnalyzingVariant,
  React
};