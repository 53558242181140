import BlogImageCompare from "../../../../src/components/BlogImageCompare";
import Caption from "../../../../src/components/Caption";
import ArenaturistAfter from "../../../../content/blog/how-to-interpret-attention-maps/images/arenaturist-after.png";
import ArenaturistAfterAttention from "../../../../content/blog/how-to-interpret-attention-maps/images/arenaturist-after_heatMap_aoi.png";
import FlosBefore from "../../../../content/blog/how-to-interpret-attention-maps/images/flos-casestudy-before.png";
import FlosBeforeAttention from "../../../../content/blog/how-to-interpret-attention-maps/images/Flos_Before_heatMap_aoi.png";
import FlosAfter from "../../../../content/blog/how-to-interpret-attention-maps/images/flos-casestudy-after.png";
import FlosAfterAttention from "../../../../content/blog/how-to-interpret-attention-maps/images/Flos_After_heatMap_aoi.png";
import * as React from 'react';
export default {
  BlogImageCompare,
  Caption,
  ArenaturistAfter,
  ArenaturistAfterAttention,
  FlosBefore,
  FlosBeforeAttention,
  FlosAfter,
  FlosAfterAttention,
  React
};