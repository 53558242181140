import React from 'react';
import { makeStyles } from '@material-ui/core';
import Caption from './Caption';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    marginTop: '2.25rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },
  item: {
    width: '50%',
  },
  img: {
    display: 'inline-block',
    maxWidth: '100%',
  },
});

const ImageSideBySide = ({ left, right, ...props }) => {
  const classes = useStyles();

  const getImageItem = (src, alt) => {
    if (!src) return;
    return (
      <div className={classes.item}>
        <img className={classes.img} src={src} alt={alt || ''} />
      </div>
    );
  };

  const getCaptionItem = (caption) => {
    if (!caption) return;
    return (
      <div className={classes.item}>
        <Caption style={{ marginTop: '0.5rem' }}>{caption}</Caption>
      </div>
    );
  };

  return (
    <div className={classes.root} {...props}>
      <div className={classes.container}>
        {left && getImageItem(left.imgSrc, left.imgAlt)}
        {right && getImageItem(right.imgSrc, right.imgAlt)}
      </div>
      <div className={classes.container}>
        {left && getCaptionItem(left.caption)}
        {right && getCaptionItem(right.caption)}
      </div>
    </div>
  );
};

ImageSideBySide.propTypes = {
  left: PropTypes.shape({
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    caption: PropTypes.node,
  }),
  right: PropTypes.shape({
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    caption: PropTypes.node,
  }),
};

export default ImageSideBySide;
