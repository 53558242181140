import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginTop: '1.2rem',
    color: 'rgba(117, 117, 117, 1)',
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    fontWeight: 400,
    '& a': {
      fontSize: '14px !important',
      lineHeight: '20px !important',
      color: 'rgba(117, 117, 117, 1) !important',
    },
  },
});

const Caption = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <figcaption className={classes.root} {...props}>
      {children}
    </figcaption>
  );
};

export default Caption;
