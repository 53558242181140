import Caption from "../../../../src/components/Caption";
import ClarityRanges from "../../../../src/components/ClarityRanges";
import BlogImageCompare from "../../../../src/components/BlogImageCompare";
import AppleImage from "../../../../content/blog/visual-clarity-guide/images/apple_original.png";
import LingsCarsImage from "../../../../content/blog/visual-clarity-guide/images/lingscars_original.jpg";
import * as React from 'react';
export default {
  Caption,
  ClarityRanges,
  BlogImageCompare,
  AppleImage,
  LingsCarsImage,
  React
};